import React from "react";
import { Link } from "gatsby";

const SignInAgain = ({ nextPath }) => {
    const param = nextPath ? `?next=${encodeURIComponent(nextPath)}` : "";

    return (
        <div className="flex flex-col md:justify-center items-center p-4 w-full h-screen -mt-16 pt-16">
            <div className="absolute inset-0 mesh-bg -z-10"></div>
            <div className="flex flex-col rounded w-full md:w-1/2 lg:w-1/3 bg-lightgreen1 px-4 py-6">
                <h1 className="text-lg mb-2">Sign in is required</h1>
                <p>
                    Please{" "}
                    <Link
                        to={`/signin${param}`}
                        className="text-blue font-bold underline"
                    >
                        sign in
                    </Link>{" "}
                    to view this page
                </p>
                <hr className="w-full border-b border-grey-light my-4" />
                <div className="flex flex-row justify-center items-center text-base">
                    <span className="px-2">Don't have an account?</span>
                    <Link
                        to="/signup"
                        className="text-black font-bold underline"
                    >
                        Sign up
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SignInAgain;
