import React, { useState } from "react";
import Loading from "./loading";
import { ErrorAlert } from "./errorAlert";
import { navigate } from "gatsby";

import { formatDate, formatPrice, formatRate } from "../helpers/formatter";

const sorterByStartTime = (a, b) => {
    if (a.start_at < b.start_at) {
        return -1;
    }
    if (a.start_at > b.start_at) {
        return 1;
    }
    return 0;
};

export const ExtraDetail = ({
    plans,
    planType,
    paramDisplayOrder,
    currentSubscriptions,
    inProgressOrders,
    userToken,
    planContext,
}) => {
    if (!currentSubscriptions) {
        currentSubscriptions = { subscriptions: {} };
    }

    if (!inProgressOrders) {
        inProgressOrders = { orders: {} };
    }

    const [isShowCurrentSubscription, setIsShowCurrentSubscription] = useState(
        false
    );

    const [selectedPriceInfo, setSelectedPriceInfo] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [invalidCoupon, setInvalidCoupon] = useState("");

    const [couponCode, setCouponCode] = useState("");

    const [coupon, setCoupon] = useState(null);

    const [isCheckingCoupon, setIsCheckingCoupon] = useState(false);

    const extraPlan = plans[0]; // assume only have one plan and one parameter
    let shortestDurationPriceInfo = extraPlan.detail.price[0];

    extraPlan.detail.price.forEach((item) => {
        if (
            (item.price_duration_unit.toLowerCase() === "month" &&
                shortestDurationPriceInfo.price_duration_unit.toLowerCase() ===
                    "year") ||
            item.price_duration < shortestDurationPriceInfo.price_duration
        ) {
            shortestDurationPriceInfo = item;
        }
    });

    let _selectedPriceInfo = extraPlan.detail.price.filter((priceInfo) => {
        return (
            selectedPriceInfo &&
            selectedPriceInfo.price_duration === priceInfo.price_duration &&
            selectedPriceInfo.price_duration_unit ===
                priceInfo.price_duration_unit
        );
    });

    if (_selectedPriceInfo.length === 0) {
        _selectedPriceInfo = shortestDurationPriceInfo;
    } else {
        _selectedPriceInfo = _selectedPriceInfo[0];
    }

    if (
        !selectedPriceInfo ||
        (selectedPriceInfo.price_duration !==
            _selectedPriceInfo.price_duration &&
            selectedPriceInfo.price_duration_unit !==
                _selectedPriceInfo.price_duration_unit)
    ) {
        setSelectedPriceInfo(_selectedPriceInfo);
    }

    let subscriptionList = currentSubscriptions.subscriptions[planType];
    subscriptionList.sort(sorterByStartTime);

    const firstSubscripiton = subscriptionList[0];

    const [quantity, setQuantity] = useState(firstSubscripiton.quantity || 1);

    const checkStatusResponse = (apiResponse) => {
        switch (apiResponse.data.status) {
            case "REDIRECT":
                window.location.href = apiResponse.data.redirect_url;
                break;
            case "PAYMENT_NOT_REQUIRED":
            default:
                navigate("/purchase/currentSubscription");
                break;
        }
    };

    const handleSubscribePaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Invalid quantity. Please refresh and try again."
                );
                console.error(
                    "Should not have invalid quantity at this request."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "ALREADY_SUBSCRIBED":
                setErrorMessage(
                    "Already subscribed. Please refresh and try again."
                );
                break;

            case "SERVER_ERROR":
            case "INVALID_PLAN_SUB_REQUEST":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleExtendPaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "SUBSCRIPTION_NOT_FOUND":
                setErrorMessage(
                    "Subscripiton not found. Please refresh and try again."
                );
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Invalid quantity. Maybe the quantity is too large. Please refresh and try again."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "INVALID_PLAN_EXTEND_REQUEST":
            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleUpgradeCurrentPaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "SUBSCRIPTION_NOT_FOUND":
                setErrorMessage(
                    "Subscripiton not found. Please refresh and try again."
                );
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Invalid quantity. Maybe the quantity is too small. Please refresh and try again."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "INVALID_PLAN_UPGRADE_REQUEST":
            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleCouponCodeError = (apiResponse) => {
        console.error(apiResponse.response);

        switch (apiResponse.response.data.error.code) {
            case "UNAUTHORIZED":
                setInvalidCoupon("Please refresh the page, or login again.");
                break;
            case "COUPON_CODE_EXPIRED":
                setInvalidCoupon("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setInvalidCoupon("Coupon code used up");
                break;
            case "COUPON_CODE_NOT_FOUND":
                setInvalidCoupon("Coupon code not found");
                break;

            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const buyNow = ({
        planID,
        priceDuration,
        priceDurationUnit,
        quantity,
        couponCode,
    }) => {
        setIsLoading(true);
        planContext
            .subscribe({
                planID,
                priceDuration,
                priceDurationUnit,
                quantity,
                couponCode,
                userToken,
            })
            .then(checkStatusResponse)
            .catch(handleSubscribePaymentError);
    };

    const extendPlan = ({
        planID,
        priceDuration,
        priceDurationUnit,
        quantity,
        couponCode,
    }) => {
        setIsLoading(true);
        planContext
            .extend({
                planID,
                priceDuration,
                priceDurationUnit,
                quantity,
                couponCode,
                userToken,
            })
            .then(checkStatusResponse)
            .catch(handleExtendPaymentError);
    };

    const upgradeCurrentSubscription = ({ planID, quantity, couponCode }) => {
        setIsLoading(true);
        quantity = parseInt(quantity, 10);
        planContext
            .upgradeCurrentSubscription({
                planID,
                quantity,
                userToken,
                couponCode,
            })
            .then(checkStatusResponse)
            .catch(handleUpgradeCurrentPaymentError);
    };

    const checkCoupon = (couponCode) => {
        planContext
            .checkCoupon({ couponCode, userToken })
            .then((res) => {
                setCoupon(res.data);
                setIsCheckingCoupon(false);
            })
            .catch((err) => {
                setCoupon(null);
                handleCouponCodeError(err);
                setIsCheckingCoupon(false);
            });
    };

    const payOrder = (orderID) => {
        setIsLoading(true);
        planContext
            .payOrder(orderID, userToken)
            .then(checkStatusResponse)
            .catch(console.log);
    };

    const cancelOrder = (orderID) => {
        setIsLoading(true);
        planContext
            .cancelOrder(orderID, userToken)
            .then(function () {
                window.location.reload();
            })
            .catch(console.log);
    };

    const dateAddition = (d, duration, durationUnit) => {
        let newDate = new Date(d);
        const month = newDate.getMonth();
        if (durationUnit.toLowerCase() === "month") {
            newDate.setMonth(month + duration);
        } else if (durationUnit.toLowerCase() === "year") {
            newDate.setMonth(month + duration * 12);
        }
        return newDate;
    };

    const isPaymentInProgress = (currentOrders) =>
        currentOrders.filter(
            (order) =>
                order.status !== "completed" &&
                order.status !== "subscription_created"
        ).length !== 0;

    const renderPlanDetails = (extraPlan, paramDisplayOrder) => {
        const jobName = paramDisplayOrder[0].key;
        const paramName = paramDisplayOrder[0].children[0].key;
        // var jobDisplayName = extraPlan.detail[jobName].display_name;
        var paramDisplayName =
            extraPlan.detail[jobName][paramName].display_name;
        // Assume one extra plan only have one parameoter
        const unitPriceCent = shortestDurationPriceInfo.unit_price_cent;
        const unitPriceCurrency = shortestDurationPriceInfo.unit_price_currency;
        const value = extraPlan.detail[jobName][paramName].parameter_value;
        const valueUnit =
            extraPlan.detail[jobName][paramName].parameter_value_unit;
        const price_duration = shortestDurationPriceInfo.price_duration;
        const price_durationUnit =
            shortestDurationPriceInfo.price_duration_unit;
        return (
            <table className="table-auto mt-8">
                <tbody>
                    <tr>
                        <td className="p-4 border-2 border-white bg-lightgreen1 ">
                            {paramDisplayName}
                        </td>
                        <td className="p-4 border-2 border-white bg-lightgreen1 ">
                            {formatPrice(unitPriceCent, unitPriceCurrency)}/
                            {formatRate(value, valueUnit)} per{" "}
                            {formatRate(price_duration, price_durationUnit)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderPaymentInProgressOrder = (relatedOrders) =>
        relatedOrders.length !== 0 ? (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header">
                        <div className="text-lg text-green1 ">
                            Payment in progress:{" "}
                            <span className="text-sm font-bold text-red ">
                                (You cannot purchase other items until the
                                payment is completed)
                            </span>{" "}
                        </div>
                    </div>

                    {relatedOrders.map((order) => {
                        if (order.action === "upgrade_current_plan") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 "
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Upgrade subscription: payment in
                                            progress
                                        </div>

                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        Upgrade {paramDisplayName} to{" "}
                                        {(
                                            order.quantity * value
                                        ).toLocaleString("en-US")}{" "}
                                        {valueUnit}
                                    </div>
                                </div>
                            );
                        }
                        if (order.action === "extend") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 my-2"
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Extend subscription: payment in
                                            progress
                                        </div>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        Extend {paramDisplayName}:{" "}
                                        {(
                                            order.quantity * value
                                        ).toLocaleString("en-US")}{" "}
                                        {valueUnit} (From{" "}
                                        {formatDate(order.start_at)} to{" "}
                                        {formatDate(order.end_at)})
                                    </div>
                                </div>
                            );
                        }
                        if (order.action === "create") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 my-2"
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Create subscription: payment in
                                            progress
                                        </div>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            );
                        }
                        return <div className="text-lg mx-8 my-2"> </div>;
                    })}
                </div>
            </div>
        ) : (
            <></>
        );

    const renderCurrentSubscription = (
        relatedSubscriptions,
        isShowCurrentSubscription
    ) =>
        relatedSubscriptions.length === 0 ? (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header">
                        <div className="text-lg text-green1">
                            No current subscription
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header">
                        <div className="text-lg text-green1">
                            Current Subscription
                        </div>
                    </div>

                    <div className={isShowCurrentSubscription ? "hidden" : ""}>
                        <div className="card-body">
                            <div className="text-lg mx-8 my-2">
                                <div>
                                    From{" "}
                                    {formatDate(
                                        new Date(
                                            relatedSubscriptions[0].start_at
                                        )
                                    )}{" "}
                                    to{" "}
                                    {formatDate(
                                        new Date(relatedSubscriptions[0].end_at)
                                    )}
                                </div>
                                <div>
                                    {paramDisplayName}:{" "}
                                    {(
                                        relatedSubscriptions[0].quantity * value
                                    ).toLocaleString("en-US")}{" "}
                                    {valueUnit}
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                relatedSubscriptions.length <= 1
                                    ? "mx-4 hidden"
                                    : "mx-4"
                            }
                        >
                            <button
                                className="btn btn-link px-0"
                                onClick={() => {
                                    setIsShowCurrentSubscription(
                                        !isShowCurrentSubscription
                                    );
                                }}
                            >
                                More &nbsp;
                                <i className={"fas fa-angle-right"}>&nbsp;</i>
                            </button>
                        </div>
                    </div>

                    <div className={isShowCurrentSubscription ? "" : "hidden"}>
                        <div className="card-body">
                            {relatedSubscriptions.map((subscription) => (
                                <div
                                    className="text-lg mx-8 my-2"
                                    key={
                                        "subscription." +
                                        subscription.subscription_id
                                    }
                                >
                                    <div>
                                        From{" "}
                                        {formatDate(
                                            new Date(subscription.start_at)
                                        )}{" "}
                                        to{" "}
                                        {formatDate(
                                            new Date(subscription.end_at)
                                        )}
                                    </div>
                                    <div>
                                        {paramDisplayName}:{" "}
                                        {(
                                            subscription.quantity * value
                                        ).toLocaleString("en-US")}{" "}
                                        {valueUnit}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mx-4">
                            <button
                                className="btn btn-link px-0"
                                onClick={() => {
                                    setIsShowCurrentSubscription(
                                        !isShowCurrentSubscription
                                    );
                                }}
                            >
                                Hide &nbsp;
                                <i className={"fas fa-angle-up"}>&nbsp;</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    const renderQuantityInput = (quantity, extraPlan, paramDisplayOrder) => {
        // Always display one job and one parameter only
        const jobName = paramDisplayOrder[0].key;
        const paramName = paramDisplayOrder[0].children[0].key;

        const paramDisplayName =
            extraPlan.detail[jobName][paramName].display_name;
        const valueUnit =
            extraPlan.detail[jobName][paramName].parameter_value_unit;

        return (
            <div className="my-2 mt-8">
                <div className="table">
                    <div className="text-lg font-bold table-cell py-2 px-4 w-48">
                        Required Quantity
                    </div>
                    <div className="table-cell py-2 px-4 w-32">
                        <input
                            id="quantity"
                            value={quantity}
                            step="1"
                            min="1"
                            max="100"
                            type="number"
                            onChange={(e) => {
                                if (e.target.value > 100) {
                                    e.target.value = 100;
                                }
                                if (e.target.value < 1) {
                                    e.target.value = 1;
                                }
                                setQuantity(e.target.value);
                            }}
                            className="rounded-full border-0 bg-lightgreen1 text-sm px-3 py-1 w-full mb-4   "
                        />
                    </div>
                    <div className="table-cell py-2 px-4 ">
                        (Total {paramDisplayName}:{" "}
                        {(quantity * value).toLocaleString("en-US")} {valueUnit}
                        )
                    </div>
                </div>
            </div>
        );
    };
    function renderPeriodSelection(
        extraPlan,
        selectedPriceInfo,
        currentSubscriptions
    ) {
        let isDisablePeriodSelection = false;

        currentSubscriptions.subscriptions[planType].forEach((subscription) => {
            const subPlan = subscription.plan;
            if (subPlan.plan_id === extraPlan.plan_id) {
                isDisablePeriodSelection =
                    isDisablePeriodSelection ||
                    quantity > subscription.quantity;
            }
        });

        const isSelected = (selectedPriceInfo, priceInfo) =>
            selectedPriceInfo &&
            selectedPriceInfo.price_duration === priceInfo.price_duration &&
            selectedPriceInfo.price_duration_unit ===
                priceInfo.price_duration_unit;

        const borderColor = isDisablePeriodSelection
            ? " border-grey-dark "
            : " border-green1 ";
        const highlightColor = isDisablePeriodSelection
            ? " text-black "
            : " text-white ";
        const highlightBgColor = isDisablePeriodSelection
            ? " bg-white "
            : " bg-green1 ";

        return (
            <>
                <div className="table selectable-table my-2">
                    <div className="text-lg font-bold table-cell py-2 px-4 w-40">
                        Plan Period
                    </div>
                    {extraPlan.detail.price.map((priceInfo, index) => (
                        <div
                            className={
                                "table-cell border-2 py-2 px-4 w-40 relative " +
                                borderColor +
                                (isDisablePeriodSelection ? " disabled " : "") +
                                (isSelected(selectedPriceInfo, priceInfo)
                                    ? highlightBgColor + " " + highlightColor
                                    : "")
                            }
                            onClick={() => {
                                if (
                                    !isDisablePeriodSelection &&
                                    !isSelected(selectedPriceInfo, priceInfo)
                                ) {
                                    setSelectedPriceInfo(priceInfo);
                                }
                            }}
                            onKeyDown={() => {
                                if (
                                    !isDisablePeriodSelection &&
                                    !isSelected(selectedPriceInfo, priceInfo)
                                ) {
                                    setSelectedPriceInfo(priceInfo);
                                }
                            }}
                            role="button"
                            tabIndex={index}
                            key={
                                "plan.priceSelector." +
                                priceInfo.price_duration +
                                "." +
                                priceInfo.price_duration_unit
                            }
                        >
                            <div>
                                {priceInfo.price_duration}{" "}
                                {priceInfo.price_duration_unit}
                            </div>
                            <div>
                                {priceInfo.unit_price_currency}{" "}
                                {typeof priceInfo.unit_price_cent === "number"
                                    ? (
                                          (priceInfo.unit_price_cent / 100) *
                                          quantity
                                      ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                      })
                                    : priceInfo.unit_price_cent / 100 +
                                      " &times; " +
                                      quantity}
                            </div>
                            {(priceInfo.price_duration ===
                                shortestDurationPriceInfo.price_duration) &
                            (priceInfo.price_duration_unit ===
                                shortestDurationPriceInfo.price_duration_unit) ? (
                                ""
                            ) : (
                                <div className="absolute right-0 top-0 mt-1 mr-1">
                                    <i
                                        className={
                                            "fas fa-gift " +
                                            (selectedPriceInfo &&
                                            selectedPriceInfo.price_duration ===
                                                priceInfo.price_duration &&
                                            selectedPriceInfo.price_duration_unit ===
                                                priceInfo.price_duration_unit &&
                                            !isDisablePeriodSelection
                                                ? " text-white "
                                                : " text-chocolate ")
                                        }
                                    ></i>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </>
        );
    }

    const renderPricingInfoHtml = (
        quantity,
        currentSubscriptions,
        selectedPriceInfo,
        coupon
    ) => {
        if (!selectedPriceInfo) {
            return <></>;
        }

        let isUpgrade = false;

        let lastSubscriptionEndDate = new Date();
        const today = new Date();
        // var upgradeCostCent = 0;

        currentSubscriptions.subscriptions[planType].forEach((subscription) => {
            const subPlan = subscription.plan;
            if (subPlan.plan_id === extraPlan.plan_id) {
                isUpgrade = isUpgrade || quantity > subscription.quantity;

                var effectiveEndDate = new Date(subscription.end_at);
                if (lastSubscriptionEndDate < effectiveEndDate) {
                    lastSubscriptionEndDate = effectiveEndDate;
                }
            }
        });

        if (isUpgrade) {
            let prorataPriceCent = 0;
            let upgradeStartDate = new Date(lastSubscriptionEndDate);
            const upgradeEndDate = new Date(lastSubscriptionEndDate);
            const _quantity = parseInt(quantity);

            currentSubscriptions.subscriptions[planType].forEach(
                (subscription) => {
                    const subPlan = subscription.plan;
                    if (subPlan.plan_id !== extraPlan.plan_id) {
                        return;
                    }
                    if (subscription.quantity >= _quantity) {
                        // subPlan quantity is more that given quantity, skip
                        return;
                    }

                    const startTime = new Date(subscription.start_at);
                    const endTime = new Date(subscription.end_at);

                    if (upgradeStartDate > startTime) {
                        // get the earliest time
                        upgradeStartDate = startTime;
                    }
                    const subscriptionPriceID = subscription.price_id;
                    const subscriptionPrice = subscription.plan.detail.price.filter(
                        (price) => price.price_id === subscriptionPriceID
                    )[0];
                    const fullPeriodPriceDiff =
                        (_quantity - subscription.quantity) *
                        subscriptionPrice.unit_price_cent;

                    const numOfMonth =
                        subscriptionPrice.price_duration_unit.toLowerCase() ===
                        "year"
                            ? 12
                            : subscriptionPrice.price_duration;

                    if (
                        startTime.getTime() < today.getTime() &&
                        today.getTime() < endTime.getTime()
                    ) {
                        //pro-rata
                        const remainingDay = Math.ceil(
                            (endTime.getTime() - today.getTime()) /
                                1000 /
                                3600 /
                                24
                        );
                        const numDatePerMonth = 30;
                        const remainingDayRatio = Math.min(
                            1,
                            remainingDay / (numDatePerMonth * numOfMonth)
                        );

                        prorataPriceCent += Math.ceil(
                            fullPeriodPriceDiff * remainingDayRatio
                        );
                    } else {
                        prorataPriceCent += fullPeriodPriceDiff;
                    }
                }
            );

            if (upgradeStartDate < today) {
                // if the subscrption period include today
                // use today
                upgradeStartDate = today;
            }

            const lastPrice = coupon
                ? Math.max(0, prorataPriceCent - coupon.discount_cent) / 100
                : prorataPriceCent / 100;

            return (
                <div>
                    <div className="text-lg">
                        Estimate:
                        <table className="m-2">
                            <tbody>
                                <tr>
                                    <td className="pr-2">
                                        Subscription start date:
                                    </td>
                                    <td>{formatDate(upgradeStartDate)}</td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        Subscription end date:
                                    </td>
                                    <td>{formatDate(upgradeEndDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full flex flex-row-reverse">
                            {coupon ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Sub Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                )
                                            </td>
                                            <td className="float-right">
                                                {(
                                                    prorataPriceCent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                {coupon.description}
                                            </td>
                                            <td className="float-right">
                                                -
                                                {(
                                                    coupon.discount_cent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            const price = (selectedPriceInfo.unit_price_cent / 100) * quantity;
            const downgradeStartDate = lastSubscriptionEndDate;
            let downgradeEndDate = new Date(downgradeStartDate);
            downgradeEndDate = dateAddition(
                downgradeEndDate,
                selectedPriceInfo.price_duration,
                selectedPriceInfo.price_duration_unit
            );

            const lastPrice = coupon
                ? Math.max(0, price - coupon.discount_cent / 100)
                : price;

            return (
                <div>
                    <div className="text-lg">
                        Estimate:
                        <table className="m-2">
                            <tbody>
                                <tr>
                                    <td className="pr-2">
                                        Subscription start date:
                                    </td>
                                    <td>{formatDate(downgradeStartDate)}</td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        Subscription end date:
                                    </td>
                                    <td>{formatDate(downgradeEndDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full flex flex-row-reverse">
                            {coupon ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Sub Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                )
                                            </td>
                                            <td className="float-right">
                                                {price.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                {coupon.description}
                                            </td>
                                            <td className="float-right">
                                                -
                                                {(
                                                    coupon.discount_cent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {price.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderButton = (
        quantity,
        currentSubscriptions,
        selectedPriceInfo,
        coupon
    ) => {
        if (!selectedPriceInfo) {
            return <></>;
        }

        let isNew = true;

        let isUpgrade = false;

        let lastSubscriptionEndDate = new Date();
        const today = new Date();

        let upgradeStartDate = new Date(lastSubscriptionEndDate);

        currentSubscriptions.subscriptions[planType].forEach((subscription) => {
            var subPlan = subscription.plan;
            if (subPlan.plan_id === extraPlan.plan_id) {
                if (subscription.subscription_id !== "") {
                    isNew = false;
                }

                isUpgrade = isUpgrade || quantity > subscription.quantity;

                const effectiveEndDate = new Date(subscription.end_at);
                if (lastSubscriptionEndDate < effectiveEndDate) {
                    lastSubscriptionEndDate = effectiveEndDate;
                }

                if (quantity > subscription.quantity) {
                    upgradeStartDate = new Date(subscription.start_at);
                }

                if (upgradeStartDate < today) {
                    // if the subscrption period include today
                    // use today
                    upgradeStartDate = today;
                }
            }
        });

        const upgradeEndDate = new Date(lastSubscriptionEndDate);

        const downgradeStartDate = lastSubscriptionEndDate;
        let downgradeEndDate = new Date(downgradeStartDate);
        downgradeEndDate = dateAddition(
            downgradeEndDate,
            selectedPriceInfo.price_duration,
            selectedPriceInfo.price_duration_unit
        );

        const buyNowButtonHtml = (
            <button
                className={"primary btn m-2 "}
                disabled={isPaymentInProgress(
                    inProgressOrders.orders[planType]
                )}
                onClick={() => {
                    let param = {
                        planID: extraPlan.plan_id,
                        priceDuration: selectedPriceInfo.price_duration,
                        priceDurationUnit:
                            selectedPriceInfo.price_duration_unit,
                        quantity: quantity,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    buyNow(param);
                }}
                key="buy"
            >
                Buy Now
            </button>
        );

        const upgradeCurrentButtonHtml = (
            <button
                className={"primary btn m-2 text-center"}
                disabled={isPaymentInProgress(
                    inProgressOrders.orders[planType]
                )}
                onClick={() => {
                    let param = {
                        planID: extraPlan.plan_id,
                        quantity: quantity,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    upgradeCurrentSubscription(param);
                }}
                key="upgrade"
            >
                Upgrade subscription
                <br></br>({formatDate(upgradeStartDate)} ~{" "}
                {formatDate(upgradeEndDate)} )
            </button>
        );

        const extendButtonHtml = (
            <button
                className={"primary btn m-2 text-center "}
                disabled={isPaymentInProgress(
                    inProgressOrders.orders[planType]
                )}
                onClick={() => {
                    let param = {
                        planID: extraPlan.plan_id,
                        priceDuration: selectedPriceInfo.price_duration,
                        priceDurationUnit:
                            selectedPriceInfo.price_duration_unit,
                        quantity: quantity,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    extendPlan(param);
                }}
                key="extend"
            >
                Extend subscription
                <br></br>({formatDate(downgradeStartDate)} ~{" "}
                {formatDate(downgradeEndDate)} )
            </button>
        );

        const buttonHtmlList = [];

        if (isNew) {
            buttonHtmlList.push(buyNowButtonHtml);
        } else {
            if (isUpgrade) {
                buttonHtmlList.push(upgradeCurrentButtonHtml);
            } else {
                buttonHtmlList.push(extendButtonHtml);
            }
        }

        return (
            <div className="flex flex-row-reverse mt-4">{buttonHtmlList}</div>
        );
    };

    const jobName = paramDisplayOrder[0].key;
    const paramName = paramDisplayOrder[0].children[0].key;

    const paramDisplayName = extraPlan.detail[jobName][paramName].display_name;
    // Assume one extra plan only have one parameoter
    const value = extraPlan.detail[jobName][paramName].parameter_value;
    const valueUnit = extraPlan.detail[jobName][paramName].parameter_value_unit;

    // var relatedSubscriptions = currentSubscriptions.subscriptions
    const relatedSubscriptions = currentSubscriptions.subscriptions[planType]
        .filter((subscription) => {
            const plan = subscription.plan;
            return (
                subscription.quantity !== undefined &&
                plan.plan_id === extraPlan.plan_id &&
                plan.detail &&
                plan.detail[jobName] &&
                plan.detail[jobName][paramName]
            );
        })
        .sort(sorterByStartTime);
    if (!inProgressOrders.orders[planType]) {
        inProgressOrders.orders[planType] = [];
    }
    const relatedOrders = inProgressOrders.orders[planType]
        .filter((order) => {
            return (
                order.status !== "completed" &&
                order.status !== "subscription_created"
            );
        })
        .sort(sorterByStartTime);

    // Update button and payment info
    const remarksHtml = (
        <div className="text-sm text-grey-darker pt-5">
            *Remarks:
            <ol className="list-decimal pl-10">
                <li>
                    Please refer to{" "}
                    <a
                        className="underline"
                        href="https://cpas.earth/business/pricing"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Pricing page
                    </a>{" "}
                    for details.
                </li>
                <li>
                    The price is an estimate and may differ from the final
                    price.
                </li>
                <li>
                    It will take effect immediately when you upgrade the plan
                    and take effect the first day after the current contract
                    expires when you downgrade the plan.
                </li>
            </ol>
        </div>
    );

    const couponCodeHtml = (couponCode, invalidCoupon) => {
        return (
            <div>
                <div>
                    <input
                        id="couponCode"
                        value={couponCode}
                        placeholder="Coupon code"
                        type="string"
                        onChange={(e) => {
                            setCouponCode(e.target.value);
                            setInvalidCoupon("");
                        }}
                        className="rounded-full border-0 bg-lightgreen1 text-sm px-3 py-1 mb-4   "
                    ></input>
                    <div className="float-right">
                        {isCheckingCoupon ? (
                            <span className="fas fa-spinner fa-spin fa-fw float-left "></span>
                        ) : (
                            <span />
                        )}
                        <button
                            disabled={isCheckingCoupon}
                            className={" btn plain sm "}
                            onClick={() => {
                                if (couponCode) {
                                    checkCoupon(couponCode);
                                    setIsCheckingCoupon(true);
                                } else {
                                    setCoupon(null);
                                }
                                setCouponCode("");
                                setInvalidCoupon("");
                            }}
                            key="couponBtn"
                        >
                            Apply Coupon
                        </button>
                    </div>
                </div>
                <div className="text-red">{invalidCoupon}</div>
            </div>
        );
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    <ErrorAlert errorMessage={errorMessage}></ErrorAlert>
                    {renderPlanDetails(extraPlan, paramDisplayOrder)}
                    {renderPaymentInProgressOrder(relatedOrders)}
                    {renderCurrentSubscription(
                        relatedSubscriptions,
                        isShowCurrentSubscription
                    )}
                    {renderQuantityInput(
                        quantity,
                        extraPlan,
                        paramDisplayOrder
                    )}
                    {renderPeriodSelection(
                        extraPlan,
                        selectedPriceInfo,
                        currentSubscriptions
                    )}
                    <hr></hr>
                    <div>
                        {renderPricingInfoHtml(
                            quantity,
                            currentSubscriptions,
                            selectedPriceInfo,
                            coupon
                        )}
                        {remarksHtml}
                        <div className="py-5 m-2">
                            {couponCodeHtml(couponCode, invalidCoupon)}
                        </div>
                        <div className="flex flex-row-reverse mt-4">
                            {renderButton(
                                quantity,
                                currentSubscriptions,
                                selectedPriceInfo,
                                coupon
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
