import React, { useContext, useState, useEffect } from "react";

import { Router } from "@reach/router";

import { CurrentUserContext } from "../../providers/auth";
import { PlanProvider, PlanContext } from "../../providers/plan";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Loading from "../../components/loading";
import SignInAgain from "../../components/sign-in-again";
import { PlanDetail } from "../../components/planDetail";
import { ExtraDetail } from "../../components/extraDetail";

import planDetailDisplayOrder from "../../assets/payment/basicPlanParameterDisplayOrder.json";
import vcpuExtraPlanDetailDisplayOrder from "../../assets/payment/vcpuExtraPlanParameterDisplayOrder.json";
import memExtraPlanDetailDisplayOrder from "../../assets/payment/memExtraPlanParameterDisplayOrder.json";
import storageExtraPlanDetailDisplayOrder from "../../assets/payment/storageExtraPlanParameterDisplayOrder.json";

// import currentPlan from "./currentPlan";

const Purchase = () => {
    return (
        <Layout>
            <PlanProvider>
                <Router>
                    <PurchasePage path="purchase/purchase" />
                </Router>
            </PlanProvider>
        </Layout>
    );
};

export default Purchase;

const PurchasePage = ({ location }) => {
    const currentUser = useContext(CurrentUserContext);
    const planContext = useContext(PlanContext);

    const [categories, setCategories] = useState(null);
    const [plans, setPlans] = useState(null);
    const [currentSubscriptions, setCurrentSubscriptions] = useState(null);
    const [inProgressOrders, setInProgressOrders] = useState(null);
    const [idToken, setIdToken] = useState(null);

    const [isFinishLoadingPlans, setIsFinishLoadingPlans] = useState(false);
    const [
        isFinishLoadingCurrentSubscriptions,
        setIsFinishLoadingCurrentSubscriptions,
    ] = useState(false);
    const [
        isFinishLoadingInProgressOrders,
        setIsFinishLoadingInProgressOrders,
    ] = useState(false);
    const [isNeedSignin, setIsNeedSignin] = useState(false);

    const [currentCategory, setCurrentCategory] = useState("basic");

    const [planDisplayOrder, setPlanDisplayOrder] = useState(null);

    useEffect(() => {
        const avaliablePlanType = [
            "basic",
            "extra_cpu",
            "extra_mem",
            "extra_storage",
        ];
        if (location.search.length > 0) {
            var query = location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (decodeURIComponent(pair[0]) === "type") {
                    var planType = decodeURIComponent(pair[1]);

                    if (!avaliablePlanType.includes(planType)) {
                        planType = avaliablePlanType[0];
                    }
                    setCurrentCategory(planType);
                }
            }
        }
        window.sessionStorage.setItem(
            "redirectAfterLogin",
            decodeURIComponent(location.href)
        );
    }, [location]);

    useEffect(() => {
        if (!planContext) {
            return;
        }

        setCategories(planContext.getCategories());
    }, [planContext]);

    useEffect(() => {
        if (!planContext) {
            return;
        }
        planContext
            .getNonExpiredSubscriptions()
            .then(({ data }) => {
                setCurrentSubscriptions(data);
                setIsFinishLoadingCurrentSubscriptions(true);
            })
            .catch(console.log);
        planContext
            .getInProgressOrders()
            .then(({ data }) => {
                setInProgressOrders(data);
                setIsFinishLoadingInProgressOrders(true);
            })
            .catch(console.log);
    }, [planContext]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        currentUser
            .getIdToken()
            .then((idToken) => {
                if (idToken) {
                    setIdToken(idToken);
                } else {
                    setIsNeedSignin(true);
                }
            })
            .catch(console.log);
    }, [currentUser]);

    useEffect(() => {
        if (idToken) {
            var promise = null;
            if (currentCategory === "basic") {
                promise = planContext.getPlans();
            }
            if (currentCategory === "extra_cpu") {
                promise = planContext.getVCpu();
            }
            if (currentCategory === "extra_mem") {
                promise = planContext.getMemory();
            }
            if (currentCategory === "extra_storage") {
                promise = planContext.getStorage();
            }

            if (promise) {
                promise
                    .then(({ data }) => {
                        var plans = data.plan_list
                            .filter((plan) => plan.level !== 0)
                            .sort((a, b) => {
                                return a.level > b.level ? 1 : -1;
                            });
                        setPlans(plans);
                        setPlanDisplayOrder(
                            plans.map((plan) => ({
                                key: plan.plan_id,
                            }))
                        );
                        setIsFinishLoadingPlans(true);
                    })
                    .catch(console.log);
            }
        }
    }, [idToken, currentCategory, planContext]);

    return (
        <>
            <Seo title="Purchase" />

            {isNeedSignin && <SignInAgain nextPath={location.href} />}
            {!isNeedSignin &&
                (!isFinishLoadingCurrentSubscriptions ||
                    !isFinishLoadingInProgressOrders ||
                    !isFinishLoadingPlans) && <Loading />}
            {!isNeedSignin &&
                isFinishLoadingCurrentSubscriptions &&
                isFinishLoadingInProgressOrders &&
                isFinishLoadingPlans && (
                    <>
                        <div className="flex flex-row items-stretch bg-grey-light h-12">
                            <div className="flex flex-row items-center flex-1 ml-4">
                                Purchase
                            </div>
                        </div>

                        <div className="md:w-4/5 lg:w-4/5 mx-auto">
                            <div className="flex flex-row">
                                <div className="w-1/4 flex flex-col">
                                    <div className={"m-4"}>
                                        {categories.map((category) => (
                                            <CategoryDom
                                                category={category}
                                                key={
                                                    "category." + category.name
                                                }
                                                setCurrentCategory={
                                                    setCurrentCategory
                                                }
                                                currentCategory={
                                                    currentCategory
                                                }
                                                setIsFinishLoadingPlans={
                                                    setIsFinishLoadingPlans
                                                }
                                            ></CategoryDom>
                                        ))}
                                    </div>
                                </div>
                                <div className="w-3/4">
                                    {currentCategory === "basic" && (
                                        <div>
                                            <h1 className="text-lg">
                                                Plan Detail
                                            </h1>
                                            <PlanDetail
                                                planDisplayOrder={
                                                    planDisplayOrder
                                                }
                                                paramDisplayOrder={
                                                    planDetailDisplayOrder
                                                }
                                                plans={plans}
                                                currentSubscriptions={
                                                    currentSubscriptions
                                                }
                                                inProgressOrders={
                                                    inProgressOrders
                                                }
                                                userToken={idToken}
                                                planContext={planContext}
                                            ></PlanDetail>
                                        </div>
                                    )}
                                    {currentCategory === "extra_cpu" && (
                                        <div>
                                            <h1 className="text-lg">Pricing</h1>
                                            <ExtraDetail
                                                plans={plans}
                                                paramDisplayOrder={
                                                    vcpuExtraPlanDetailDisplayOrder
                                                }
                                                // section="jupyter_cpu_limit"
                                                planType="extra_cpu"
                                                currentSubscriptions={
                                                    currentSubscriptions
                                                }
                                                inProgressOrders={
                                                    inProgressOrders
                                                }
                                                userToken={idToken}
                                                planContext={planContext}
                                            ></ExtraDetail>
                                        </div>
                                    )}
                                    {currentCategory === "extra_mem" && (
                                        <div>
                                            <h1 className="text-lg">Pricing</h1>
                                            <ExtraDetail
                                                plans={plans}
                                                paramDisplayOrder={
                                                    memExtraPlanDetailDisplayOrder
                                                }
                                                // section="jupyter_memory_limit"
                                                planType="extra_mem"
                                                currentSubscriptions={
                                                    currentSubscriptions
                                                }
                                                inProgressOrders={
                                                    inProgressOrders
                                                }
                                                userToken={idToken}
                                                planContext={planContext}
                                            ></ExtraDetail>
                                        </div>
                                    )}
                                    {currentCategory === "extra_storage" && (
                                        <div>
                                            <h1 className="text-lg">Pricing</h1>
                                            <ExtraDetail
                                                plans={plans}
                                                paramDisplayOrder={
                                                    storageExtraPlanDetailDisplayOrder
                                                }
                                                // section="storage_limit"
                                                planType="extra_storage"
                                                currentSubscriptions={
                                                    currentSubscriptions
                                                }
                                                inProgressOrders={
                                                    inProgressOrders
                                                }
                                                userToken={idToken}
                                                planContext={planContext}
                                            ></ExtraDetail>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </>
    );
};

const CategoryDom = ({
    category,
    currentCategory,
    setCurrentCategory,
    setIsFinishLoadingPlans,
}) => {
    return !category.child ? (
        <div
            className={
                "p-2 flex flex-auto" +
                (currentCategory === category.name
                    ? " text-white bg-green1 "
                    : " text-black ")
            }
        >
            <div
                className={" no-underline cursor-pointer "}
                onClick={() => {
                    setIsFinishLoadingPlans(false);
                    setCurrentCategory(category.name);
                    const state = window.history.state;
                    window.history.pushState(
                        state,
                        document.title,
                        "/purchase/purchase/?type=" + category.name
                    );
                }}
                onKeyDown={() => {
                    setIsFinishLoadingPlans(false);
                    setCurrentCategory(category.name);
                    const state = window.history.state;
                    window.history.pushState(
                        state,
                        document.title,
                        "/purchase/purchase/?type=" + category.name
                    );
                }}
                role="button"
                tabIndex={0}
            >
                <i className="mr-4 fas fa-caret-square-right"></i>
                {category.displayName}
            </div>
        </div>
    ) : (
        <div className="m-2 flex flex-col flex-auto">
            <div className="my-2 flex w-full border-b-2 border-gray-300">
                <span className="text-black no-underline">
                    {category.displayName}
                </span>
            </div>
            {category.child.map((childCat) => {
                return (
                    <CategoryDom
                        category={childCat}
                        key={"category." + childCat.name}
                        setCurrentCategory={setCurrentCategory}
                        currentCategory={currentCategory}
                        setIsFinishLoadingPlans={setIsFinishLoadingPlans}
                    ></CategoryDom>
                );
            })}
        </div>
    );
};
