import React, { Fragment, useEffect, useState } from "react";
import Loading from "./loading";

import { ErrorAlert } from "./errorAlert";
import { navigate } from "gatsby";
import { formatDate } from "../helpers/formatter";

const getInitialSelectedPlan = (
    planDisplayOrder,
    plans,
    currentSubscriptions
) => {
    let searchOrder = JSON.parse(JSON.stringify(planDisplayOrder));

    if (currentSubscriptions.subscriptions["basic"].length !== 0) {
        // If user has subscription already, use that plan
        if (currentSubscriptions.subscriptions["basic"][0].plan.detail.price) {
            const firstPlanId =
                currentSubscriptions.subscriptions["basic"][0].plan_id;
            searchOrder.unshift({ key: firstPlanId });
        }
    }

    let initSelectedPlan = null;
    for (let i in searchOrder) {
        const searchItem = searchOrder[i];
        const result = plans.filter((plan) => {
            return plan.plan_id === searchItem.key;
        });
        if (result.length !== 0) {
            initSelectedPlan = result[0];
            break;
        }
    }
    return initSelectedPlan;
};

const getSelectedPriceInfo = (selectedPlan, selectedPriceInfo) => {
    let _selectedPriceInfo = null;
    let possiblePriceInfo = [];
    if (!selectedPlan) {
        return null;
    }
    if (selectedPriceInfo) {
        // if user already selected a duration
        // keep using that duration
        possiblePriceInfo = selectedPlan.detail.price.filter(
            (priceInfo) =>
                selectedPriceInfo.price_duration === priceInfo.price_duration &&
                selectedPriceInfo.price_duration_unit ===
                    priceInfo.price_duration_unit
        );
    }
    if (possiblePriceInfo.length === 0) {
        // If user have not selected a duration (probably first load)
        // Use first price info
        _selectedPriceInfo = selectedPlan.detail.price[0];
    } else {
        _selectedPriceInfo = possiblePriceInfo[0];
    }

    return _selectedPriceInfo;
};

function dateAddition(d, duration, durationUnit) {
    let newDate = new Date(d);
    const month = newDate.getMonth();
    if (durationUnit.toLowerCase() === "month") {
        newDate.setMonth(month + duration);
    } else if (durationUnit.toLowerCase() === "year") {
        newDate.setMonth(month + duration * 12);
    }
    return newDate;
}

const sorterByStartTime = (a, b) => {
    if (a.start_at < b.start_at) {
        return -1;
    }
    if (a.start_at > b.start_at) {
        return 1;
    }
    return 0;
};

export const PlanDetail = ({
    plans,
    planDisplayOrder,
    paramDisplayOrder,
    currentSubscriptions,
    inProgressOrders,
    userToken,
    planContext,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [invalidCoupon, setInvalidCoupon] = useState("");

    const [couponCode, setCouponCode] = useState("");

    const [coupon, setCoupon] = useState(null);

    const [isCheckingCoupon, setIsCheckingCoupon] = useState(false);

    const [isShowCurrentSubscription, setIsShowCurrentSubscription] = useState(
        false
    );

    const subscriptionPlanPurchaseAllowedList = planDisplayOrder.map(
        (plan) => plan.key
    );
    subscriptionPlanPurchaseAllowedList.push("free");

    const checkStatusResponse = (apiResponse) => {
        switch (apiResponse.data.status) {
            case "REDIRECT":
                window.location.href = apiResponse.data.redirect_url;
                break;
            case "PAYMENT_NOT_REQUIRED":
            default:
                navigate("/purchase/currentSubscription");
                break;
        }
    };

    const handleSubscribePaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                console.error(
                    "Should not have invalid quantity at this request."
                );
                break;

            case "ALREADY_SUBSCRIBED":
                setErrorMessage(
                    "Already subscribed. Please refresh and try again."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "SERVER_ERROR":
            case "INVALID_PLAN_SUB_REQUEST":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleCouponCodeError = (apiResponse) => {
        console.error(apiResponse.response);

        switch (apiResponse.response.data.error.code) {
            case "UNAUTHORIZED":
                setInvalidCoupon("Please refresh the page, or login again.");
                break;

            case "COUPON_CODE_EXPIRED":
                setInvalidCoupon("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setInvalidCoupon("Coupon code used up");
                break;
            case "COUPON_CODE_NOT_FOUND":
                setInvalidCoupon("Coupon code not found");
                break;

            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleExtendPaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "SUBSCRIPTION_NOT_FOUND":
                setErrorMessage(
                    "Subscripiton not found. Please refresh and try again."
                );
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Invalid quantity. Maybe the quantity is too large. Please refresh and try again."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "INVALID_PLAN_EXTEND_REQUEST":
            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const handleUpgradeCurrentPaymentError = (apiResponse) => {
        // TODO: pop out error message
        console.error(apiResponse);
        setIsLoading(false);

        switch (apiResponse.response.data.code) {
            case "UNAUTHORIZED":
                setErrorMessage("Please refresh the page, or login again.");
                break;

            case "SUBSCRIPTION_NOT_FOUND":
                setErrorMessage(
                    "Subscripiton not found. Please refresh and try again."
                );
                break;

            case "MISSING_PLAN_ID":
                setErrorMessage(
                    "Plan ID is missing. Please refresh and try again."
                );
                break;

            case "PLAN_NOT_FOUND":
                setErrorMessage(
                    "Plan is not found. Please refresh and try again."
                );
                break;

            case "INVALID_PRICE_INFO":
                setErrorMessage(
                    "Incorrect duration selected. Please refresh and try again."
                );
                break;

            case "INVALID_QUANTITY":
                setErrorMessage(
                    "Invalid quantity. Maybe the quantity is too small. Please refresh and try again."
                );
                break;

            case "INVALID_COUPON_CODE":
                setErrorMessage("Invalid coupon code");
                break;

            case "COUPON_CODE_EXPIRED":
                setErrorMessage("Coupon code expired");
                break;
            case "COUPON_CODE_USED_UP":
                setErrorMessage("Coupon code used up");
                break;

            case "INVALID_PLAN_UPGRADE_REQUEST":
            case "SERVER_ERROR":
            default:
                setErrorMessage(
                    "Internal server error. Please refresh and try again."
                );
                break;
        }
    };

    const buyNow = ({
        planID,
        priceDuration,
        priceDurationUnit,
        quantity,
        couponCode,
    }) => {
        setIsLoading(true);
        planContext
            .subscribe({
                planID,
                priceDuration,
                priceDurationUnit,
                quantity,
                couponCode,
                userToken,
            })
            .then(checkStatusResponse)
            .catch(handleSubscribePaymentError);
    };

    const extendPlan = ({
        planID,
        priceDuration,
        priceDurationUnit,
        quantity,
        couponCode,
    }) => {
        setIsLoading(true);
        planContext
            .extend({
                planID,
                priceDuration,
                priceDurationUnit,
                quantity,
                couponCode,
                userToken,
            })
            .then(checkStatusResponse)
            .catch(handleExtendPaymentError);
    };

    const upgradeCurrentSubscription = ({ planID, couponCode }) => {
        setIsLoading(true);
        planContext
            .upgradeCurrentSubscription({ planID, userToken, couponCode })
            .then(checkStatusResponse)
            .catch(handleUpgradeCurrentPaymentError);
    };

    const checkCoupon = (couponCode) => {
        planContext
            .checkCoupon({ couponCode, userToken })
            .then((res) => {
                setCoupon(res.data);
                setIsCheckingCoupon(false);
            })
            .catch((err) => {
                setCoupon(null);
                handleCouponCodeError(err);
                setIsCheckingCoupon(false);
            });
    };

    const payOrder = (orderID) => {
        setIsLoading(true);
        planContext
            .payOrder(orderID, userToken)
            .then(checkStatusResponse)
            .catch(console.log);
    };

    const cancelOrder = (orderID) => {
        setIsLoading(true);
        planContext
            .cancelOrder(orderID, userToken)
            .then(function () {
                window.location.reload();
            })
            .catch(console.log);
    };

    const initialPlans = getInitialSelectedPlan(
        planDisplayOrder,
        plans,
        currentSubscriptions
    );
    const [selectedPlan, setSelectedPlan] = useState(initialPlans);

    const [selectedPriceInfo, setSelectedPriceInfo] = useState(
        getSelectedPriceInfo(initialPlans, null)
    );

    useEffect(() => {
        const newSelectedPriceInfo = getSelectedPriceInfo(
            selectedPlan,
            selectedPriceInfo
        );

        if (
            !selectedPriceInfo ||
            (selectedPriceInfo.price_duration !==
                newSelectedPriceInfo.price_duration &&
                selectedPriceInfo.price_duration_unit !==
                    newSelectedPriceInfo.price_duration_unit) ||
            selectedPriceInfo.price_id !== newSelectedPriceInfo.price_id
        ) {
            setSelectedPriceInfo(newSelectedPriceInfo);
        }
    }, [selectedPlan, selectedPriceInfo]);

    const renderPlanDetails = (plans, planDisplayOrder, paramDisplayOrder) => {
        let rowIndex = 1;
        const bgList = ["bg-lightgreen1", "bg-lightgreen3"];
        let bgColor = "";
        return (
            <table className="table-auto plan-detail-table ">
                <colgroup>
                    <col />
                    <col />
                    {planDisplayOrder.map((order) => {
                        return (
                            <Fragment key={"table.col." + order.key + ".data"}>
                                <col />
                                <col className="plan-col" />
                            </Fragment>
                        );
                    })}
                </colgroup>
                <tbody>
                    {/* Insert plan name at top */}
                    <PlanDetailRow
                        rowHeader={{
                            jobName: "",
                            jobDisplayName: "",
                            paramName: "",
                            paramDisplayName: "",
                        }}
                        data={planDisplayOrder.map((order, index) => {
                            const planId = order.key;
                            const filterPlan = plans.filter(function (plan) {
                                return plan.plan_id === planId;
                            });
                            if (filterPlan.length === 0) {
                                // If cannot find the plan
                                return {
                                    planId: "plan" + index,
                                    value: "",
                                };
                            }
                            const plan = filterPlan[0];
                            return {
                                planId: plan.plan_id,
                                value: plan.display_name,
                            };
                        })}
                        rowSpan={1}
                        bgColorClass="bg-white"
                    ></PlanDetailRow>

                    {paramDisplayOrder.map((firstOrder, jobIndex) => {
                        // For each job type
                        const firstOrderKey = firstOrder.key;

                        return firstOrder.children.map(
                            (secondOrder, paramIndex) => {
                                // For each parameter
                                const secondOrderKey = secondOrder.key;

                                const data = [];

                                let rowHeader = {
                                    jobDisplayName: "",
                                    paramDisplayName: "",
                                };

                                planDisplayOrder.forEach(
                                    (planOrder, planIndex) => {
                                        // For each plan
                                        const planId = planOrder.key;

                                        const filterPlan = plans.filter(
                                            function (plan) {
                                                return plan.plan_id === planId;
                                            }
                                        );

                                        if (filterPlan.length === 0) {
                                            // If cannot find the plan
                                            data.push({
                                                planId: "plan_" + planIndex,
                                                value: "",
                                                valueUnit: "",
                                            });
                                        } else {
                                            const plan = filterPlan[0];

                                            rowHeader.jobDisplayName =
                                                plan.detail[
                                                    firstOrderKey
                                                ].display_name;
                                            rowHeader.paramDisplayName =
                                                plan.detail[firstOrderKey][
                                                    secondOrderKey
                                                ].display_name;
                                            data.push({
                                                planId: plan.plan_id,
                                                value:
                                                    plan.detail[firstOrderKey][
                                                        secondOrderKey
                                                    ]["parameter_value"],
                                                valueUnit:
                                                    plan.detail[firstOrderKey][
                                                        secondOrderKey
                                                    ]["parameter_value_unit"],
                                            });
                                        }
                                    }
                                );

                                let rowSpan = null;
                                if (paramIndex === 0) {
                                    rowIndex = 1 - rowIndex;
                                    bgColor = bgList[rowIndex];
                                    rowSpan = firstOrder.children.length;
                                }
                                return (
                                    <PlanDetailRow
                                        key={
                                            "planDetail." +
                                            firstOrderKey +
                                            "." +
                                            secondOrderKey
                                        }
                                        rowHeader={rowHeader}
                                        data={data}
                                        rowSpan={rowSpan}
                                        bgColorClass={bgColor}
                                    ></PlanDetailRow>
                                );
                            }
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderPaymentInProgressOrder = (relatedOrders) =>
        relatedOrders.length !== 0 ? (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header">
                        <div className="text-lg text-green1 ">
                            Payment in progress:{" "}
                            <span className="text-sm font-bold text-red ">
                                (You cannot purchase other items until the
                                payment is completed)
                            </span>{" "}
                        </div>
                    </div>

                    {relatedOrders.map((order) => {
                        if (order.action === "upgrade_current_plan") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 "
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Upgrade subscription: payment in
                                            progress
                                        </div>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>{" "}
                                    </div>

                                    {/* <div>
                                            Upgrade {order.plan.displayName}:{" "}
                                        </div> */}
                                </div>
                            );
                        }
                        if (order.action === "extend") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 "
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Extend subscription: payment in
                                            progress
                                        </div>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                    {/* <div>Extend {order.plan.displayName}: </div> */}
                                </div>
                            );
                        }
                        if (order.action === "create") {
                            return (
                                <div
                                    className="text-lg mx-8 my-2 "
                                    key={order.order_id}
                                >
                                    <div className="flex flex-row">
                                        <div className="">
                                            Create subscription: payment in
                                            progress
                                        </div>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                payOrder(order.order_id)
                                            }
                                        >
                                            Pay
                                        </button>
                                        <button
                                            className="mx-2 p-0 btn plain"
                                            onClick={() =>
                                                cancelOrder(order.order_id)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    {/* <div>Create {order.plan.displayName}: </div> */}
                                </div>
                            );
                        }
                        return (
                            <div className="text-sm text-red mx-8 my-2"> </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            <></>
        );

    const renderCurrentSubscription = (relatedSubscriptions) =>
        relatedSubscriptions.length === 0 ? (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <div className="text-lg text-green1 font-bold">
                            No current subscription
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="my-2 mt-8">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <div className="text-lg text-green1 font-bold">
                            Current Subscription
                        </div>
                    </div>
                    <div className={isShowCurrentSubscription ? "hidden" : ""}>
                        <div className="card-body">
                            <div className="text-lg mx-8 my-2">
                                <div>
                                    {relatedSubscriptions[0].plan.display_name}:
                                    From{" "}
                                    {formatDate(
                                        new Date(
                                            relatedSubscriptions[0].start_at
                                        )
                                    )}{" "}
                                    to{" "}
                                    {formatDate(
                                        new Date(relatedSubscriptions[0].end_at)
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                relatedSubscriptions.length <= 1
                                    ? "mx-4 hidden"
                                    : "mx-4"
                            }
                        >
                            <button
                                className="btn btn-link px-0"
                                onClick={() => {
                                    setIsShowCurrentSubscription(
                                        !isShowCurrentSubscription
                                    );
                                }}
                            >
                                More &nbsp;
                                <i className={"fas fa-angle-right"}>&nbsp;</i>
                            </button>
                        </div>
                    </div>

                    <div className={isShowCurrentSubscription ? "" : "hidden"}>
                        <div className="card-body">
                            {relatedSubscriptions.map((subscription, idx) => {
                                return (
                                    <div
                                        className="text-lg mx-8 my-2"
                                        key={"current_subscription_" + idx}
                                    >
                                        <div>
                                            {subscription.plan.display_name}:
                                            From{" "}
                                            {formatDate(
                                                new Date(subscription.start_at)
                                            )}{" "}
                                            to{" "}
                                            {formatDate(
                                                new Date(subscription.end_at)
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mx-4">
                            <button
                                className="btn btn-link px-0"
                                onClick={() => {
                                    setIsShowCurrentSubscription(
                                        !isShowCurrentSubscription
                                    );
                                }}
                            >
                                Hide &nbsp;
                                <i className={"fas fa-angle-up"}>&nbsp;</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

    const renderPlanSelector = (
        selectedPlan,
        plans,
        planDisplayOrder,
        currentSubscriptions
    ) => {
        const isSelected = (selectedPlan, plan) =>
            selectedPlan.plan_id === plan.plan_id;

        let isDisabled = false;
        currentSubscriptions.subscriptions["basic"].forEach(
            (subscription) =>
                (isDisabled =
                    isDisabled ||
                    subscriptionPlanPurchaseAllowedList.indexOf(
                        subscription.plan_id
                    ) === -1)
        );

        const borderColor = isDisabled
            ? " border-grey-dark "
            : " border-green1 ";
        const highlightColor = isDisabled ? " text-black " : " text-white ";
        const highlightBgColor = isDisabled ? " bg-white " : " bg-green1 ";

        return (
            <div className="my-2 mt-16">
                <div className="table w-full">
                    <div className="text-lg font-bold table-cell py-2 px-4 w-1/4">
                        Plan Option
                    </div>
                    <div className="table w-full">
                        {planDisplayOrder.map((order, index) => {
                            const planId = order.key;
                            const filterPlans = plans.filter(
                                (plan) => plan.plan_id === planId
                            );
                            // If cannot find plan, assign an empty dictionary
                            const plan =
                                filterPlans.length === 0 ? {} : filterPlans[0];
                            return (
                                <div
                                    className={
                                        "table-cell border-2 py-2 px-4 relative align-middle" +
                                        borderColor +
                                        (isSelected(selectedPlan, plan)
                                            ? highlightColor +
                                              " " +
                                              highlightBgColor
                                            : "")
                                    }
                                    onClick={() => {
                                        if (
                                            !isSelected(selectedPlan, plan) &&
                                            !isDisabled
                                        ) {
                                            setSelectedPlan(plan);
                                        }
                                    }}
                                    onKeyDown={() => {
                                        if (
                                            !isSelected(selectedPlan, plan) &&
                                            !isDisabled
                                        ) {
                                            setSelectedPlan(plan);
                                        }
                                    }}
                                    tabIndex={index}
                                    role="button"
                                    key={"plan.selector." + plan.plan_id}
                                >
                                    {plan.display_name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const renderPeriodSelector = (
        selectedPlan,
        selectedPriceInfo,
        currentSubscriptions
    ) => {
        const isSelected = (selectedPriceInfo, priceInfo) =>
            selectedPriceInfo.price_duration === priceInfo.price_duration &&
            selectedPriceInfo.price_duration_unit ===
                priceInfo.price_duration_unit;

        if (!selectedPlan || !selectedPriceInfo) {
            return <></>;
        }

        let isForbiddenPlan = false;
        currentSubscriptions.subscriptions["basic"].forEach(
            (subscription) =>
                (isForbiddenPlan =
                    isForbiddenPlan ||
                    subscriptionPlanPurchaseAllowedList.indexOf(
                        subscription.plan_id
                    ) === -1)
        );

        let isUpgrade = false;

        currentSubscriptions.subscriptions["basic"].forEach((subscription) => {
            isUpgrade =
                isUpgrade ||
                (subscription.level > 0 &&
                    subscription.level < selectedPlan.level);
        });

        const isDisablePeriodSelection = isUpgrade || isForbiddenPlan;

        const borderColor = isDisablePeriodSelection
            ? " border-grey-dark "
            : " border-green1 ";
        const highlightColor = isDisablePeriodSelection
            ? " text-black "
            : " text-white ";
        const highlightBgColor = isDisablePeriodSelection
            ? " bg-white "
            : " bg-green1 ";

        return (
            <>
                <div className="my-2 ">
                    <div className="table w-full">
                        <div className="text-lg font-bold table-cell py-2 px-4 w-1/4">
                            Plan Period
                        </div>
                        {selectedPlan.detail.price.map((priceInfo, index) => (
                            <div
                                className={
                                    "table-cell border-2 py-2 px-4 w-1/4 relative " +
                                    borderColor +
                                    (selectedPriceInfo &&
                                    isSelected(selectedPriceInfo, priceInfo)
                                        ? highlightBgColor +
                                          " " +
                                          highlightColor
                                        : "")
                                }
                                onClick={() => {
                                    if (
                                        !isDisablePeriodSelection &&
                                        !isSelected(
                                            selectedPriceInfo,
                                            priceInfo
                                        )
                                    ) {
                                        setSelectedPriceInfo(priceInfo);
                                    }
                                }}
                                onKeyDown={() => {
                                    if (
                                        !isDisablePeriodSelection &&
                                        !isSelected(
                                            selectedPriceInfo,
                                            priceInfo
                                        )
                                    ) {
                                        setSelectedPriceInfo(priceInfo);
                                    }
                                }}
                                tabIndex={10 + index}
                                role="button"
                                key={
                                    "plan.priceSelector." +
                                    priceInfo.price_duration +
                                    "." +
                                    priceInfo.price_duration_unit
                                }
                            >
                                <div>
                                    {priceInfo.price_duration}{" "}
                                    {priceInfo.price_duration_unit}
                                </div>
                                <div>
                                    {priceInfo.unit_price_currency}{" "}
                                    {typeof priceInfo.unit_price_cent ===
                                    "number"
                                        ? (
                                              priceInfo.unit_price_cent / 100
                                          ).toLocaleString("en-US", {
                                              minimumFractionDigits: 2,
                                          })
                                        : priceInfo.unit_price_cent / 100}
                                </div>
                                {(priceInfo.price_duration === 1) &
                                (priceInfo.price_duration_unit === "Month") ? (
                                    ""
                                ) : (
                                    <div className="absolute right-0 top-0 mt-1 mr-1">
                                        <i
                                            className={
                                                "fas fa-gift " +
                                                (selectedPriceInfo &&
                                                isSelected(
                                                    selectedPriceInfo,
                                                    priceInfo
                                                ) &&
                                                !isUpgrade
                                                    ? " text-white "
                                                    : " text-chocolate ")
                                            }
                                        ></i>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    const renderPricingInfoHtml = (
        currentSubscriptions,
        selectedPriceInfo,
        selectedPlan,
        coupon
    ) => {
        if (!selectedPlan || !selectedPriceInfo) {
            return <></>;
        }

        let isUpgrade = false;

        let lastSubscriptionEndDate = new Date();
        const today = new Date();

        let isForbiddenPlan = false;
        currentSubscriptions.subscriptions["basic"].forEach(
            (subscription) =>
                (isForbiddenPlan =
                    isForbiddenPlan ||
                    subscriptionPlanPurchaseAllowedList.indexOf(
                        subscription.plan_id
                    ) === -1)
        );

        currentSubscriptions.subscriptions["basic"].forEach((subscription) => {
            var subPlan = subscription.plan;

            isUpgrade =
                isUpgrade ||
                (subPlan.level > 0 && subPlan.level < selectedPlan.level);

            const effectiveEndDate = new Date(subscription.end_at);
            if (lastSubscriptionEndDate < effectiveEndDate) {
                lastSubscriptionEndDate = effectiveEndDate;
            }
        });

        if (isUpgrade) {
            let prorataPriceCent = 0;
            let upgradeStartDate = new Date(lastSubscriptionEndDate);
            const upgradeEndDate = new Date(lastSubscriptionEndDate);

            currentSubscriptions.subscriptions["basic"].forEach(
                (subscription) => {
                    const startTime = new Date(subscription.start_at);
                    const endTime = new Date(subscription.end_at);

                    if (upgradeStartDate > startTime) {
                        // get the earliest time
                        upgradeStartDate = startTime;
                    }
                    const subscriptionPriceID = subscription.price_id;
                    const subscriptionPrice = subscription.plan.detail.price.filter(
                        (price) => price.price_id === subscriptionPriceID
                    )[0];
                    const upgradedPlanPrice = selectedPlan.detail.price.filter(
                        (price) =>
                            price.price_duration ===
                                subscriptionPrice.price_duration &&
                            price.price_duration_unit ===
                                subscriptionPrice.price_duration_unit
                    )[0];

                    if (
                        upgradedPlanPrice === null ||
                        typeof upgradedPlanPrice === "undefined"
                    ) {
                        // upgrade plan price not found
                        return;
                    }
                    const fullPeriodPriceDiff =
                        upgradedPlanPrice.unit_price_cent -
                        subscriptionPrice.unit_price_cent;

                    const numOfMonth =
                        subscriptionPrice.price_duration_unit.toLowerCase() ===
                        "year"
                            ? 12
                            : subscriptionPrice.price_duration;

                    if (
                        startTime.getTime() < today.getTime() &&
                        today.getTime() < endTime.getTime()
                    ) {
                        //pro-rata
                        const remainingDay = Math.ceil(
                            (endTime.getTime() - today.getTime()) /
                                1000 /
                                3600 /
                                24
                        );
                        const numDatePerMonth = 30;
                        const remainingDayRatio = Math.min(
                            1,
                            remainingDay / (numDatePerMonth * numOfMonth)
                        );

                        prorataPriceCent += Math.ceil(
                            fullPeriodPriceDiff * remainingDayRatio
                        );
                    } else {
                        prorataPriceCent += fullPeriodPriceDiff;
                    }
                }
            );

            if (upgradeStartDate < today) {
                // if the subscrption period include today
                // use today
                upgradeStartDate = today;
            }

            const lastPrice = coupon
                ? Math.max(0, prorataPriceCent - coupon.discount_cent) / 100
                : prorataPriceCent / 100;

            return (
                <div>
                    <div className="text-lg">
                        Estimate:
                        <table className="m-2">
                            <tbody>
                                <tr>
                                    <td className="pr-2">
                                        Subscription start date:
                                    </td>
                                    <td>{formatDate(upgradeStartDate)}</td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        Subscription end date:
                                    </td>
                                    <td>{formatDate(upgradeEndDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full flex flex-row-reverse">
                            {coupon ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Sub Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                )
                                            </td>
                                            <td className="float-right">
                                                {(
                                                    prorataPriceCent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                {coupon.description}
                                            </td>
                                            <td className="float-right">
                                                -
                                                {(
                                                    coupon.discount_cent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Pro-rata Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            const price = selectedPriceInfo.unit_price_cent / 100;
            const downgradeStartDate = lastSubscriptionEndDate;
            let downgradeEndDate = new Date(downgradeStartDate);
            downgradeEndDate = dateAddition(
                downgradeEndDate,
                selectedPriceInfo.price_duration,
                selectedPriceInfo.price_duration_unit
            );

            const lastPrice = coupon
                ? Math.max(
                      0,
                      (selectedPriceInfo.unit_price_cent -
                          coupon.discount_cent) /
                          100
                  )
                : price;

            return (
                <div>
                    <div className="text-lg">
                        Estimate:
                        <table className="m-2">
                            <tbody>
                                <tr>
                                    <td className="pr-2">
                                        Subscription start date:
                                    </td>
                                    <td>{formatDate(downgradeStartDate)}</td>
                                </tr>
                                <tr>
                                    <td className="pr-2">
                                        Subscription end date:
                                    </td>
                                    <td>{formatDate(downgradeEndDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full flex flex-row-reverse">
                            {coupon ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Sub Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                )
                                            </td>
                                            <td className="float-right">
                                                {price.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                {coupon.description}
                                            </td>
                                            <td className="float-right">
                                                -
                                                {(
                                                    coupon.discount_cent / 100
                                                ).toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-2">
                                                Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {lastPrice.toLocaleString(
                                                    "en-US",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-2">
                                                Total Price (
                                                {
                                                    selectedPriceInfo.unit_price_currency
                                                }
                                                ):
                                            </td>
                                            <td className="float-right">
                                                {price.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const isPaymentInProgress = (currentOrders) =>
        currentOrders.filter(
            (order) =>
                order.status !== "completed" &&
                order.status !== "subscription_created"
        ).length !== 0;
    const renderButton = (
        plans,
        currentSubscriptions,
        inProgressOrders,
        selectedPlan,
        selectedPriceInfo,
        coupon
    ) => {
        let isNew = true;

        let isUpgrade = false;

        let lastSubscriptionEndDate = new Date();
        const today = new Date();

        let isForbiddenPlan = false;
        currentSubscriptions.subscriptions["basic"].forEach(
            (subscription) =>
                (isForbiddenPlan =
                    isForbiddenPlan ||
                    subscriptionPlanPurchaseAllowedList.indexOf(
                        subscription.plan_id
                    ) === -1)
        );

        currentSubscriptions.subscriptions["basic"].forEach((subscription) => {
            var subPlan = subscription.plan;
            // if (subPlan.plan_id === selectedPlan.plan_id) {
            if (subscription.subscription_id !== "") {
                isNew = false;
            }

            isUpgrade =
                isUpgrade ||
                (subPlan.level > 0 && subPlan.level < selectedPlan.level);

            const effectiveEndDate = new Date(subscription.end_at);
            if (lastSubscriptionEndDate < effectiveEndDate) {
                lastSubscriptionEndDate = effectiveEndDate;
            }
            // }
        });

        let upgradeStartDate = new Date(lastSubscriptionEndDate);

        if (isUpgrade) {
            currentSubscriptions.subscriptions["basic"].forEach(
                (subscription) => {
                    const startTime = new Date(subscription.start_at);

                    if (upgradeStartDate > startTime) {
                        // get the earliest time
                        upgradeStartDate = startTime;
                    }
                }
            );

            if (upgradeStartDate < today) {
                // if the subscrption period include today
                // use today
                upgradeStartDate = today;
            }
        }
        const downgradeStartDate = lastSubscriptionEndDate;
        let downgradeEndDate = new Date(downgradeStartDate);

        downgradeEndDate = dateAddition(
            downgradeEndDate,
            selectedPriceInfo.price_duration,
            selectedPriceInfo.price_duration_unit
        );

        const buyNowButtonHtml = (
            <button
                className={"primary btn m-2 "}
                disabled={
                    isPaymentInProgress(inProgressOrders.orders["basic"]) ||
                    isForbiddenPlan
                }
                onClick={() => {
                    let param = {
                        planID: selectedPlan.plan_id,
                        priceDuration: selectedPriceInfo.price_duration,
                        priceDurationUnit:
                            selectedPriceInfo.price_duration_unit,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    buyNow(param);
                }}
                key="subscribeBtn"
            >
                Buy Now
            </button>
        );

        const extendButtonHtml = (
            <button
                className={"primary btn m-2 text-center"}
                disabled={
                    isPaymentInProgress(inProgressOrders.orders["basic"]) ||
                    isForbiddenPlan
                }
                onClick={() => {
                    let param = {
                        planID: selectedPlan.plan_id,
                        priceDuration: selectedPriceInfo.price_duration,
                        priceDurationUnit:
                            selectedPriceInfo.price_duration_unit,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    extendPlan(param);
                }}
                key="extendBtn"
            >
                Extend subscription
                <br></br>({formatDate(downgradeStartDate)} ~{" "}
                {formatDate(downgradeEndDate)} )
            </button>
        );

        const upgradeButtonHtml = (
            <button
                className={"primary btn m-2 text-center"}
                disabled={
                    isPaymentInProgress(inProgressOrders.orders["basic"]) ||
                    isForbiddenPlan
                }
                onClick={() => {
                    let param = {
                        planID: selectedPlan.plan_id,
                    };
                    if (!!coupon) {
                        param.couponCode = coupon.coupon_code;
                    }
                    upgradeCurrentSubscription(param);
                }}
                key="upgradeBtn"
            >
                Upgrade subscription
                <br></br>({formatDate(upgradeStartDate)} ~{" "}
                {formatDate(lastSubscriptionEndDate)} )
            </button>
        );

        const buttonHtmlList = [];

        if (isNew) {
            buttonHtmlList.push(buyNowButtonHtml);
        } else {
            if (isUpgrade) {
                buttonHtmlList.push(upgradeButtonHtml);
            } else {
                buttonHtmlList.push(extendButtonHtml);
            }
        }
        return <div>{buttonHtmlList}</div>;
    };

    useEffect(() => {
        if (!selectedPlan || !selectedPriceInfo) {
            return;
        }
    }, [selectedPlan, selectedPriceInfo, isShowCurrentSubscription]);

    const remarksHtml = (
        <div className="text-sm text-grey-darker pt-5 ">
            *Remarks:
            <ol className="list-decimal pl-10">
                <li>
                    Please refer to{" "}
                    <a
                        className="underline"
                        href="https://cpas.earth/business/pricing"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Pricing page
                    </a>{" "}
                    for details.
                </li>
                <li>
                    It will take effect immediately when you upgrade the plan
                    and take effect the first day after the current contract
                    expires when you downgrade the plan.
                </li>
            </ol>
        </div>
    );

    const couponCodeHtml = (couponCode, invalidCoupon) => {
        return (
            <div>
                <div>
                    <input
                        id="couponCode"
                        value={couponCode}
                        placeholder="Coupon code"
                        type="string"
                        onChange={(e) => {
                            setCouponCode(e.target.value);
                            setInvalidCoupon("");
                        }}
                        className="rounded-full border-0 bg-lightgreen1 text-sm px-3 py-1 mb-4   "
                    ></input>
                    <div className="float-right">
                        {isCheckingCoupon ? (
                            <span className="fas fa-spinner fa-spin fa-fw float-left "></span>
                        ) : (
                            <span />
                        )}
                        <button
                            disabled={isCheckingCoupon}
                            className={" btn plain sm "}
                            onClick={() => {
                                if (couponCode) {
                                    checkCoupon(couponCode);
                                    setIsCheckingCoupon(true);
                                } else {
                                    setCoupon(null);
                                }
                                setCouponCode("");
                                setInvalidCoupon("");
                            }}
                            key="couponBtn"
                        >
                            Apply Coupon
                        </button>
                    </div>
                </div>
                <div className="text-red">{invalidCoupon}</div>
            </div>
        );
    };

    if (!inProgressOrders.orders["basic"]) {
        inProgressOrders.orders["basic"] = [];
    }
    const relatedOrders = inProgressOrders.orders["basic"]
        .filter((order) => {
            return (
                order.status !== "completed" &&
                order.status !== "subscription_created"
            );
        })
        .sort(sorterByStartTime);

    let relatedSubscriptions = [];
    if (currentSubscriptions.subscriptions["basic"]) {
        relatedSubscriptions = currentSubscriptions.subscriptions["basic"]
            .sort(sorterByStartTime)
            .filter((subscription) => {
                return subscription.subscription_id !== "";
            });
    }

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    <ErrorAlert errorMessage={errorMessage}></ErrorAlert>

                    {renderPlanDetails(
                        plans,
                        planDisplayOrder,
                        paramDisplayOrder
                    )}
                    {renderPaymentInProgressOrder(relatedOrders)}
                    {renderCurrentSubscription(relatedSubscriptions)}
                    {renderPlanSelector(
                        selectedPlan,
                        plans,
                        planDisplayOrder,
                        currentSubscriptions
                    )}
                    {renderPeriodSelector(
                        selectedPlan,
                        selectedPriceInfo,
                        currentSubscriptions
                    )}

                    <hr></hr>
                    <div>
                        {renderPricingInfoHtml(
                            currentSubscriptions,
                            selectedPriceInfo,
                            selectedPlan,
                            coupon
                        )}
                        {remarksHtml}
                        <div className="py-5 m-2">
                            {couponCodeHtml(couponCode, invalidCoupon)}
                        </div>
                        <div className="flex flex-row-reverse mt-4">
                            {renderButton(
                                plans,
                                currentSubscriptions,
                                inProgressOrders,
                                selectedPlan,
                                selectedPriceInfo,
                                coupon
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const PlanDetailRow = ({
    rowHeader,
    data,
    rowSpan = null,
    bgColorClass = "",
}) => (
    <tr>
        {rowSpan !== null ? (
            <td
                className={" text-sm p-4 border-2 border-white " + bgColorClass}
                rowSpan={rowSpan}
            >
                {rowHeader.jobDisplayName}
            </td>
        ) : (
            <></>
        )}
        <td className={" text-sm p-4 border-2 border-white " + bgColorClass}>
            {rowHeader.paramDisplayName}
        </td>
        {data.map(({ planId, value, valueUnit }) => (
            <Fragment
                key={
                    "cell." +
                    rowHeader.jobDisplayName +
                    "." +
                    rowHeader.paramDisplayName +
                    "." +
                    planId
                }
            >
                <td className="spacer border-2 border-white"></td>
                <td
                    className={
                        "text-sm w-32 text-center relative  h-full border-2 border-white " +
                        bgColorClass
                    }
                >
                    {typeof value === "number"
                        ? value.toLocaleString("en-US")
                        : value}{" "}
                    {valueUnit}
                </td>
            </Fragment>
        ))}
    </tr>
);
export default PlanDetail;
